body {
  margin: 0;
  font-family: "ProximaSoft",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Input Telephone Number CSS */
.special-label{
  color:#00000099 !important;
  font-size: 12px !important;
}

.react-tel-input:focus .special-label{
  color:#0cba70 !important;
}

.form-control{
  max-width: -webkit-fill-available;
  height:41px !important
}

.react-tel-input .form-control:focus{
  border-color: #0cba70 !important;
  box-shadow: none !important;
}
.react-tel-input>.form-control:focus>.special-label{
  color:#0cba70 !important;
}


.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: none !important;
}
.swal2-popup .swal2-modal .swal2-show {
  background: red !important;
}